/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import SocialMedia from "../components/social-medias"

import GamotionSvg from "../assets/gamotion.svg"

import Header from "./header"
import Pdf from "../assets/gameMatress.pdf"
import Pdf2 from "../assets/instruction.pdf"
import Pdf5 from "../assets/presentation.pdf"
import Pdf4 from "../assets/long.pdf"
import Pdf3 from "../assets/short.pdf"

import Pdf6 from "../assets/Buckinx2022.pdf"
import Dropdown from 'react-bootstrap/Dropdown'
import "../assets/css/bootstrap/bootstrap.css";
import "../assets/css/style.css"
import "../assets/css//media-queries.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
      
    }
  `)

  return (
    <>
      <Header menuLinks={data.site.siteMetadata.menuLinks} siteTitle={data.site.siteMetadata.title} />

      <div
        style={{
          margin: `0 auto`,
          // maxWidth: 1200,
          // padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer>

          <span className={"footer-top"}>
            <span className={"footer-top-left"}><GamotionSvg style={{
              width: "150px",
            }} /><div className={"footer-contact"}>
                {/* <h3>Contact</h3> */}
                <p><b>SARQOL SRL </b><br />
                SPEES CHU Sart-Tilman, Bat. B23 <br />
                Avenue Hippocrate 13 <br />
                4000 Liège - Belgique<br />
                info@gamotion.net | Tél : +32 43 66 25 81
              </p></div></span>

            <span className={"footer-top-right"}>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  PDF documentation
             </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href={Pdf}>Game Matress</Dropdown.Item>
                  <Dropdown.Item href={Pdf2}>Instructions</Dropdown.Item>
                  <Dropdown.Item href={Pdf3}>Buckinx et al. 2020</Dropdown.Item>
                  <Dropdown.Item href={Pdf4}>Mouton et al. 2017</Dropdown.Item>
                  <Dropdown.Item href={Pdf5}>Presentation form</Dropdown.Item>
                <Dropdown.Item key={"6"} href={Pdf6}>Buckinx et al. 2022</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <nav>
                <h3>Menu</h3>
                {data.site.siteMetadata.menuLinks.map((link, index) => (

                  <Nav.Link as="div" href="#page-2" key={index} eventKey={link.link} className="link-no-style">
                    <Link to={link.name} className="link-no-style">

                      {link.name}

                    </Link>
                  </Nav.Link>

                ))}</nav> */}
              {/* <nav>

                <Nav.Link as="div" href="#page-2" eventKey={"#"} className="link-no-style">
                  <Link to={"#"} className="link-no-style">

                    About us

                </Link>
                </Nav.Link>
                <Nav.Link as="div" href="#page-2" eventKey={"#"} className="link-no-style">
                  <Link to={"#"} className="link-no-style">

                    Terms & conditions

                </Link>
                </Nav.Link>
                <Nav.Link as="div" href="#page-2" eventKey={"#"} className="link-no-style">
                  <Link to={"#"} className="link-no-style">

                    Privacy policy

                </Link>
                </Nav.Link>

              </nav> */}
            </span>
          </span>
          <span className={"footer-bottom"}>©copyright 2020 <SocialMedia height={"20px"} /> </span>

        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
