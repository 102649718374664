import React from "react"
//import TwitterLogo from "../assets/Twitter.svg"
import LinkedinLogo from "../assets/Linkedin.svg"
import FacebookLogo from "../assets/Facebook.svg"

import MailLogo from "../assets/mail.svg"

function SocialMedia({ height }) {
    return (
        <div style={{
            height: height,
            // maxWidth: 1200,
            // padding: `0 1.0875rem 1.45rem`,
        }} className={"social-container"}>
            {/* <TwitterLogo /> */}
            <a href="https://www.linkedin.com/company/gamotion/"><LinkedinLogo name="linkedin" alt="linkedin" /></a>

            <a href="mailto:info@gamotion.net"><MailLogo name="mail" alt="mail" /></a>
            <a href="https://www.facebook.com/GAMotion.net"><FacebookLogo name="facebook" alt="facebook" /></a>
        </div>
    )
}
export default SocialMedia