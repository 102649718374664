import React, { useState } from 'react'
import { Link } from "gatsby"
import PropTypes from "prop-types"
import GamotionSvg from "../assets/gamotion.svg"
import Pdf from "../assets/gameMatress.pdf"
import Pdf2 from "../assets/instruction.pdf"
import Pdf4 from "../assets/long.pdf"
import Pdf5 from "../assets/presentation.pdf"
import Pdf6 from "../assets/Buckinx2022.pdf"
import Pdf3 from "../assets/short.pdf"
import Dropdown from 'react-bootstrap/Dropdown'
import { Navbar, Nav, Button } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal'
import SocialMedia from "../components/social-medias"

const Header = ({ siteTitle, menuLinks }) => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <header>
      <div
        style={{
          backgroundColor: " rgba(196, 196, 196, 0.15)",
          color: "black",
        }}
      >
        <Navbar variant="light" expand="md" id="site-navbar" >
          <Link to="/" name="home" className="link-no-style">
            <Navbar.Brand as="span"><GamotionSvg style={{
              width: "250px",
            }} />
              <i>The motion by the game</i>
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">



            {menuLinks.map((link, index) => (
              <Nav.Link as="span" key={index} eventKey={link.link} className="link-no-style">
                <Link to={link.name} className="link-no-style">

                  {link.name}

                </Link>
              </Nav.Link>
            ))}

            <Button variant="link" className={"header-modal"} onClick={handleShow}>
              Contact Us
            </Button>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Contact</Modal.Title>
              </Modal.Header>
              <Modal.Body><div className={"footer-contact"}>
                {/* <h3>Contact</h3> */}
                <p><b>SARQOL SRL </b><br />
                SPEES CHU Sart-Tilman, Bat. B23 <br />
                Avenue Hippocrate 13 <br />
                4000 Liège - Belgique<br />
                info@gamotion.net | Tél : +32 43 66 25 81
              </p></div></Modal.Body>
              <Modal.Footer>

                <SocialMedia height={"30px"} />
                {/* <a href="mailto:info@gamotion.net"><MailLogo /></a> */}
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Dropdown>
              <Dropdown.Toggle variant="success">
                PDF documentation
             </Dropdown.Toggle>

              <Dropdown.Menu id="personalDropdown">
                <Dropdown.Item key={"1"} href={Pdf}>Game Mattress</Dropdown.Item>
                <Dropdown.Item key={"2"} href={Pdf2}>Instructions</Dropdown.Item>
                <Dropdown.Item key={"3"} href={Pdf3}>Buckinx et al. 2020</Dropdown.Item>
                <Dropdown.Item key={"4"} href={Pdf4}>Mouton et al. 2017</Dropdown.Item>
                <Dropdown.Item key={"5"} href={Pdf5}>Presentation form</Dropdown.Item>
                <Dropdown.Item key={"6"} href={Pdf6}>Buckinx et al. 2022</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <Button>
            <a href={Pdf} className="link-no-style">

              PDF documentation

            </a>
          </Button> */}
          </Navbar.Collapse>
        </Navbar>

      </div>
    </header >
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header